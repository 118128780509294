const securityOptions = {
  encryptionSecret: undefined,
  isCompression: false,
};

//localhost
if (process.env.VUE_APP_ENIVIRONMENT === "MOCK" || process.env.VUE_APP_ENIVIRONMENT === "DEV") {
  Object.assign(securityOptions, {
    encodingType: "",
  });
}

//production  development
//if (process.env.NODE_ENV === "production")
/*Object.assign(securityOptions, {
    encodingType: "",
    encryptionSecret: "cucoriedka",
    isCompression: false,
  });*/

export default securityOptions;
