// this is a global directive that can be use like : v-can="NameofTheRole"
//  !!!!!!! Now we don't use permissions at the role level. !!!!!!!!!!! --> check helper with name permission-checker.js

const can = {
  beforeMount(el, binding) {
    const role = binding.arg; // get the role from the argument
    const store = binding.instance.$store.state.auth.user.user.roles; // get the user roles
    try {
      // loop through the store array and check each object's name property
      let hasRole = false; // a flag to indicate if the user has the role or not
      for (let i = 0; i < store.length; i++) {
        if (store[i].name === role) {
          // if the object's name matches the role, set the flag to true and break the loop
          hasRole = true;
          break;
        }
      }
      if (!hasRole) {
        // hide the element if the user doesn't have the role
        el.style.display = "none";
      }
    } catch (error) {
      console.error(error); // log the error message
    }
  },
};

export default can;
