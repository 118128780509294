import { Module, VuexModule, Action, Mutation } from "vuex-module-decorators";
import { unref } from "vue";
import { Endpoints } from "@/store/enums/ApiEnums";
import AxiosService from "@/services/AxiosService";
import useRemoveDuplicates from "@/composables/removeDuplicates";

@Module({ namespaced: true })
class AdChannelsInCampaignsStore extends VuexModule {
  public Items = {};
  public Channels = {};
  public CampaignId = null;
  public RefLink = { url: "", error: "" };
  public RefLinkArr = [] as any;

  get fetchedItems() {
    return this.Items;
  }

  get fetchedChannels() {
    return this.Channels;
  }

  get refLink() {
    return this.RefLink;
  }

  get refLinkArr() {
    return this.RefLinkArr;
  }

  @Mutation
  SET_ITEMS(payload: any): void {
    this.Items = payload;
  }

  @Mutation
  SET_CHANNELS(payload: any): void {
    this.Channels = payload;
  }

  @Mutation
  SET_CAMPAIGN_ID(payload: any): void {
    this.CampaignId = payload;
  }

  @Mutation
  SET_REF_LINK(payload: any): void {
    this.RefLink = payload;
  }

  @Mutation
  PUSH_IN_REFLINK_ARR(payload: any): void {
    this.RefLinkArr.push(payload);
  }

  @Mutation
  CLEAR_REF_LINK(): void {
    this.RefLink = { url: "", error: "" };
  }
  @Mutation
  CLEAR_REF_LINK_ARR(): void {
    this.RefLinkArr = [];
  }

  @Action
  public setCampaignId(val) {
    this.context.commit("SET_CAMPAIGN_ID", val);
  }

  @Action
  public approvedAdChannelsInCampaigns(query: string | string[]) {
    const filter = "?filter[status][eq]=1&expand[]=channel&expand[]=campaign";
    const q = unref(query);
    if (q.length > 2) {
      const queryTerm = "&filter[campaign.name][like]=" + q;
      AxiosService.get(Endpoints.ENDPOINT_AD_CHANNELS_IN_CAMPAIGN_BASE + filter + queryTerm).then((response) => {
        const data = response.data.data;
        const { noDuplicates } = useRemoveDuplicates("campaign_id", data);
        const noNullCampaigns = noDuplicates.filter(i => i.campaign);
        this.context.commit("SET_ITEMS", noNullCampaigns);
      });
    } else {
      AxiosService.get(Endpoints.ENDPOINT_AD_CHANNELS_IN_CAMPAIGN_BASE + filter).then((response) => {
        const data = response.data.data;
        const { noDuplicates } = useRemoveDuplicates("campaign_id", data);
        const noNullCampaigns = noDuplicates.filter(i => i.campaign);
        this.context.commit("SET_ITEMS", noNullCampaigns);
      });
    }
  }

  @Action
  public adChannelsOfGivenCampaign(query: string | string[]) {
    const filter = "?filter[status][eq]=A&expand[]=adChannels";
    const q = unref(query);
    if (q.length > 2) {
      const queryTerm = "&filter[name][like]=" + q;
      AxiosService.get(Endpoints.ENDPOINT_CAMPAIGN + "/" + this.CampaignId + filter + queryTerm).then((response) => {
        const data = response.data.data.ad_channels;
        this.context.commit("SET_CHANNELS", data);
      });
    } else {
      AxiosService.get(Endpoints.ENDPOINT_CAMPAIGN + "/" + this.CampaignId + filter).then((response) => {
        const data = response.data.data.ad_channels;
        this.context.commit("SET_CHANNELS", data);
      });
    }
  }

  @Action
  public generateLink(body) {
    const b = unref(body);
    AxiosService.post(Endpoints.ENDPOINT_CAMPAIGN_GENERATE_LINK, b, false).then((response) => {
      const data = response.data;
      const configData = JSON.parse(response.config.data);
      const typeUrl = configData.url_type;
      const order = configData.order;
      data["url_type"] = data.type_url ?? typeUrl;
      data["order"] = order;
      this.context.commit("SET_REF_LINK", data);
      this.context.commit("PUSH_IN_REFLINK_ARR", data);
    }).catch((error) => {
      const configData = JSON.parse(error.response.config.data);
      const order = configData.order;
      this.context.commit("SET_REF_LINK", {
        error: error.response.data.message,
        status: error.response.data.status,
        order
      }
      );
      this.context.commit("PUSH_IN_REFLINK_ARR", {
        error: error.response.data.message,
        status: error.response.data.status,
        order
      });
    });
  }

  @Action
  public setCustomLink(link) {
    this.context.commit("SET_REF_LINK", link);
  }

  @Action
  public clearLink() {
    this.context.commit("CLEAR_REF_LINK");
  }

  @Action
  public clearRefLinkArr() {
    this.context.commit("CLEAR_REF_LINK_ARR");
  }
}

export default AdChannelsInCampaignsStore;
