import { RouteRecordRaw } from "vue-router";

export const CampaignFeed: Array<RouteRecordRaw> = [
  {
    path: "/campaign-feeds",
    name: "campaign-feed-index",
    component: () => import("@/views/feed/campaign-feed-management/CampaignFeedIndex.vue"),
    meta: { resources: ["CampaignFeed"] },
  },
];
export default CampaignFeed;
