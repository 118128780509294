import { RouteRecordRaw } from "vue-router";

export const Click: Array<RouteRecordRaw> = [
  {
    path: "/clicks",
    name: "click-management-index",
    component: () => import("@/views/click/click-management/ClickManagementIndex.vue"),
    meta: { resources: ["Click"] },
  },
];
export default Click;
