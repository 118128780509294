import { RouteRecordRaw } from "vue-router";

export const GenerateLink: Array<RouteRecordRaw> = [
  {
    path: "/generate-link",
    name: "generate-link-index",
    component: () => import("@/views/generate-link/GenerateLinkIndex.vue"),
  },
];
export default GenerateLink;
