import { RouteRecordRaw } from "vue-router";

export const Commission: Array<RouteRecordRaw> = [
  {
    path: "/commission-comparison",
    name: "commission-comparison",
    component: () => import("@/views/commission/commission-comparison/CommissionComparisonIndex.vue"),
    meta: { resources: ["commissionComparison"] },
  },
];
export default Commission;
