import { RouteRecordRaw } from "vue-router";

export const Page: Array<RouteRecordRaw> = [
  {
    path: "/file/file-manager",
    name: "file-manager-index",
    component: () => import("@/views/file/file-manager/FileManager.vue"),
    meta: { resources: ["file"] },
    children: [
      {
        path: "/file/file-manager",
        name: "file-manager-index",
        component: () => import("@/views/file/file-manager/FileManagerIndex.vue"),
        meta: { resources: ["file"] },
      },
      {
        path: "/file/file-manager/upload",
        name: "file-manager-upload",
        component: () => import("@/views/file/file-manager/FileManagerCreate.vue"),
        meta: { resources: ["file"] },
      },
      {
        path: "/file/file-manager/detail/:id",
        name: "file-manager-show",
        component: () => import("@/views/file/file-manager/FileManagerShow.vue"),
        meta: { resources: ["file"] },
      },
      {
        path: "/file/file-manager/edit/:id",
        name: "file-manager-edit",
        component: () => import("@/views/file/file-manager/FileManagerEdit.vue"),
        meta: { resources: ["file"] },
      },
    ],
  },
];
export default Page;
