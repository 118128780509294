import { i18n } from "@/helpers/i18nSet";
const { t } = i18n.global;

export default function usei18nTfuncWrapper() {
  return (key: any) => {
    const fullKeyTranslation = t(key);
    if (fullKeyTranslation !== key) {
      return fullKeyTranslation;
    }

    if (key.includes(".")) {
      const parts = key.split(".");
      if (parts.length > 1) {
        key = parts[0] + "." + parts.slice(1).join("_");
      }
    }
    return t(key);
  };
}
