import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import store from "@/store";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import filters from "@/helpers/filters";
import AxiosService from "@/services/AxiosService";
import { Endpoints } from "@/store/enums/ApiEnums";

interface DataTask {
  backgroundTaskId: number;
  data: Record<string, unknown[]>;
}

interface DataTaskArray extends Array<DataTask> {}

interface Tasks {
  [resource: string]: DataTaskArray;
}

@Module({ namespaced: true, store })
class Task extends VuexModule {
  public backgroundTask: Tasks = {};

  @Mutation
  public updateTask(payload: any): void {
    this.backgroundTask = payload;
  }

  @Mutation
  public updateTaskByResource(payload: any): void {
    const { index, resource, backgroundTaskId, data } = payload;

    if (!(resource in this.backgroundTask)) {
      this.backgroundTask[resource] = [];
    }

    this.backgroundTask[resource][index] = { backgroundTaskId: backgroundTaskId, data: data };
  }

  @Mutation
  public setBackgroundTask(payload: any): void {
    const { resource, backgroundTaskId, data } = payload;

    if (!(resource in this.backgroundTask)) {
      this.backgroundTask[resource] = [];
    }

    this.backgroundTask[resource].push({ backgroundTaskId: backgroundTaskId, data: data });
  }

  @Action
  isTaskActive(props: any): boolean {
    let _data;

    if (!this.backgroundTask[props.resource]) {
      return false;
    }

    if (this.backgroundTask[props.resource].length === 0) {
      store.dispatch(Actions.DELETE_PROGRESS, props.name);
    }

    for (const [key, value] of Object.entries(this.backgroundTask[props.resource])) {
      if (value !== null && Object.keys(value.data).length > 0) {
        _data = { ...value.data };

        const total = _data.total ?? 0;
        const pending = _data.pending ?? 0;
        const failed = _data.failed ?? 0;

        store.commit(Mutations.SET_PROGRESS_BAR, {
          [props.name]: {
            id: value.backgroundTaskId,
            status: _data.status,
            percentage: filters.percentageCalculation(Number(total), Number(pending), Number(failed)),
            result: {
              total: _data.total,
              pending: _data.pending,
              failed: _data.failed,
              download_url: _data.log_url,
            },
          },
        });
      }
    }

    return true;
  }

  @Action
  deleteActiveTask(props: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (!this.backgroundTask[props.resource]) {
        resolve(false);
        return;
      }

      // const index = this.backgroundTask[props.resource].findIndex((item) => item.backgroundTaskId === props.id);
      const index = store.state.task.backgroundTask[props.resource]
        .map((item, index) => (item.backgroundTaskId === props.id ? index : -1))
        .filter((index) => index !== -1);

      if (index.length > 0) {
        //Sort the index in descending order
        index.sort((a, b) => b - a);

        for (const _index of index) {
          if (_index >= 0 && _index < store.state.task.backgroundTask[props.resource].length) {
            store.state.task.backgroundTask[props.resource].splice(_index, 1);
          }
        }

        // Delete resource if it is empty
        this.backgroundTask[props.resource] = this.backgroundTask[props.resource].filter((item) => item !== null);
        this.context.commit("updateTask", this.backgroundTask);
        this.context.dispatch("isTaskActive", props);
        resolve(true);
      } else {
        resolve(false);
      }
    });
  }
  @Action
  pingBackgroundTask(payload) {
    const { source, id } = payload;
    let pollingInterval: string | number | NodeJS.Timeout | undefined;

    AxiosService.get(filters.dynamicEnum(Endpoints.ENDPOINT_BACKGROUND_TASK_ID, [{ key: ":id", value: id }]))
      .then((response) => {
        let _data;

        const _response = response?.data;

        const _newData = {
          total: 0,
          pending: 0,
          failed: 0,
          log_url: null,
        };

        if (_response.status === 1 && _response.start === null) {
          _data = {
            ..._response,
            ..._newData,
          };
        } else {
          _data = {
            ..._response,
          };
        }

        const total = _data.total ?? 0;
        const pending = _data.pending ?? 0;
        const failed = _data.failed ?? 0;

        const percentage = filters.percentageCalculation(total, pending, failed);

        store.commit(Mutations.SET_PROGRESS_BAR, {
          selectAll: {
            status: _data.status,
            percentage: percentage,
            result: {
              total: _data.total,
              pending: _data.pending,
              failed: _data.failed,
              download_url: _data.log_url,
            },
          },
        });

        //on first load, check if task exist
        if (this.backgroundTask && this.backgroundTask[source]) {
          const index = this.backgroundTask[source].findIndex((item) => item.backgroundTaskId === id);
          if (index !== -1) {
            //update task
            store.commit(Mutations.UPDATE_TASK_BY_RESOURCE, {
              index: index,
              resource: source,
              backgroundTaskId: id,
              data: _data,
            });
          } else {
            //create new task
            store.commit(Mutations.SET_TASK, {
              resource: source,
              backgroundTaskId: id,
              data: _data,
            });
          }
        } else {
          //create new task
          store.commit(Mutations.SET_TASK, {
            resource: source,
            backgroundTaskId: id,
            data: _data,
          });
        }

        if (_data.status === 0 || _data.status === 1 || _data.status === 2) {
          pollingInterval = setTimeout(() => this.context.dispatch("pingBackgroundTask", { source, id }), 1000); // Call the function again in 1 seconds 1000ms = 1s
          return _data;
        } else {
          clearTimeout(pollingInterval);
          return _data;
        }
      })
      .catch((error) => {
        return error;
      });
  }
}

export default Task;
