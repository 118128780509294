import { RouteRecordRaw } from "vue-router";

export const Dashboard: Array<RouteRecordRaw> = [
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/views/dashboard/DashboardOverview.vue"),
  },
];
export default Dashboard;
