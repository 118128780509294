<script lang="ts">
  import { Vue } from "vue-class-component";
  import { Prop } from "vue-property-decorator";
  import { useMyToast } from "@/helpers/myToast";

  export default class NotificationsMessage extends Vue {
    @Prop({ default: [] })
    notification!: any;

    mounted() {
      const toast = useMyToast();

      const data = this.setToast();

      if (this.notification.type === "success") {
        toast.success(data[0], data[1]);
      } else if (this.notification.type === "warning") {
        toast.warning(data[0], data[1]);
      } else if (this.notification.type === "error") {
        toast.error(data[0], data[1]);
      } else {
        toast.info(data[0], data[1]);
      }
    }

    setToast() {
      const reason = this.notification.reason ? this.notification.reason : "";
      const status = this.notification.status > 299 ? this.notification.status : "";

      const title = status + " " + reason;
      const body = this.notification.body;

      return [title, body];
    }
  }
</script>
