import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import AxiosService from "@/services/AxiosService";
import { router } from "@/router";
import getLocale from "@/helpers/getLocale";
import { Endpoints } from "@/store/enums/ApiEnums";
import store from "@/store";

@Module({ namespaced: true, store })
class Enum extends VuexModule {
  public enumerators = {};

  get getEnums() {
    return this.enumerators;
  }

  @Mutation
  public setEnumerators(enumerators: string): void {
    this.enumerators = enumerators;
  }

  @Action
  initializeEnum(lang = "") {
    router.isReady().then(() => {
      const query = router.currentRoute.value.query; //get query from url

      const locale = lang === "" ? getLocale(query) : getLocale(lang); //helper class return locale

      //const locale = getLocale(query); //helper class return locale
      this.context.dispatch("fetchEnumerators", locale);
    });
  }

  @Action({ rawError: true })
  fetchEnumerators(locale: string): Promise<any> {
    return AxiosService.get(Endpoints.ENDPOINT_COMMON_ENUM + locale).then(
      (response) => {
        this.context.commit("setEnumerators", response.data.data);
        return Promise.resolve(response);
      },
      (error) => {
        const message =
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return Promise.reject(message);
      }
    );
  }
}

export default Enum;
