
import { Module, VuexModule, Action, Mutation } from "vuex-module-decorators";
import { unref } from "vue";
import { Endpoints } from "@/store/enums/ApiEnums";
import AxiosService from "@/services/AxiosService";
import useRemoveDuplicates from "@/composables/removeDuplicates";

@Module({ namespaced: true })
class AccountsStore extends VuexModule {
  public Items = {};
 initialAccount = {};
  public ActiveAccount = this.initialAccount;

  get activeAccount() {
    return this.ActiveAccount;
  }

  get fetchedItems() {
    return this.Items;
  }

  @Mutation
  SET_ITEMS(payload: any): void {
    this.Items = payload;
  }

  @Mutation
  SET_ACCOUNT(payload: any): void {
    this.ActiveAccount = payload;
  }

  @Mutation
  CLEAR_ACCOUNT(): void {
    this.ActiveAccount = this.initialAccount;
  }

  @Action
  public clearAccount() {
    this.context.commit("CLEAR_ACCOUNT");
  };

  @Action
  public getAccounts(query: string | string[]) {
    if (query.length > 2) {
      const filter = "?filter" + "[name]" + "[like]=" + query;

      AxiosService.get(Endpoints.ENDPOINT_ACCOUNT + filter).then(
        (response) => {
          this.context.commit("SET_ITEMS", response.data.data);
        },
      );
    } else {
      AxiosService.get(Endpoints.ENDPOINT_ACCOUNT).then(
        (response) => {
          this.context.commit("SET_ITEMS", response.data.data);
        },
      );
    }
  }
}

export default AccountsStore;
