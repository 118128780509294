import store from "@/store";
import { nextTick } from "vue";
import axios from "axios";

export default async function authHeader() {
  //const storedUser = localStorage.getItem("dgt");
  //const user = JSON.parse(storedUser ? storedUser : "{}");

  return new Promise((resolve) => {
    nextTick(() => {
      const loggedIn = store.state.auth.status ? store.state.auth.status.loggedIn : "";
      const accessToken = store.state.auth.user ? store.state.auth.user.token : "";

      if (loggedIn && accessToken) {
        axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
        resolve({ Authorization: "Bearer " + accessToken });
      }
    });
  });
}
