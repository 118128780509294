import { createI18n } from "vue-i18n";

const _local = process.env.VUE_APP_I18N_LOCALE || "en-US";
const _fallbackLocale = process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en";
const _messages = {};

export const i18n = createI18n({
  missingWarn: false,
  fallbackWarn: false,
  locale: _local,
  fallbackLocale: _fallbackLocale,
  messages: _messages,
  globalInjection: true,
  legacy: false,
});
