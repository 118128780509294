import { RouteRecordRaw } from "vue-router";

export const AdChannel: Array<RouteRecordRaw> = [
  {
    path: "/ad-channels",
    name: "ad-channels-management-index",
    component: () => import("@/views/ad-channel/ad-channel-management/AdChannelManagementIndex.vue"),
    meta: { resources: ["adChannel"] },
  },
  {
    path: "/ad-channels-in-campaigns",
    name: "ad-channels-in-campaigns-management-index",
    component: () =>
      import("@/views/ad-channel/ad-channel-in-campaign-management/AdChannelInCampaignManagementIndex.vue"),
    meta: { resources: ["AdChannelCampaignMap"] },
  },
];
export default AdChannel;
