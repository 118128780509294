import { RouteRecordRaw } from "vue-router";

export const Translation: Array<RouteRecordRaw> = [
  {
    path: "/translations",
    name: "translation",
    component: () => import("@/views/translation/translation-management/TranslationManagementIndex.vue"),
    meta: { resources: ["Translation"] },
  },
];
export default Translation;
