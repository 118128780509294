export default function useRemoveDuplicates(objectProperty: string, data: { [key: string]: any }) {
  const seenCodes = new Set<string>();

  const noDuplicates = data.filter((value) => {
    if (seenCodes.has(value[objectProperty])) {
      return false;
    } else {
      seenCodes.add(value[objectProperty]);
      return true;
    }
  });

  return {
    noDuplicates
  };
}
