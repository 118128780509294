import store from "@/store";

export default function getLocale(query: any) {
  let localeValue;
  if (typeof query === "string" && !store.state.auth.status.loggedIn) {
    localeValue = query;
  } else if (typeof query === "string" && store.state.auth.status.loggedIn) {
    localeValue = query;
  } else if ("locale" in query && !store.state.auth.status.loggedIn) {
    localeValue = query.locale;
  } else if (store.state.auth.status.loggedIn) {
    localeValue = store.state.auth.user.user.gui_locale;
  } else {
    const val = localStorage.getItem("locale");
    if (val && !store.state.auth.status.loggedIn) {
      localeValue = val; //set Lange from localstorage, after refresh
    } else {
      localeValue = process.env.VUE_APP_I18N_LOCALE || "en-US";
    }
  }

  return localeValue;
}
