import { RouteRecordRaw } from "vue-router";

export const Log: Array<RouteRecordRaw> = [
  {
    path: "/logs/:tab",
    name: "logs",
    component: () => import("@/views/log/LogIndex.vue"),
    meta: { resources: ["saleLog", "auditLog", "systemLog"] },
    props: true,
  },
];
export default Log;
