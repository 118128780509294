import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import AxiosService from "@/services/AxiosService";
import { router } from "@/router";
import getLocale from "@/helpers/getLocale";
import { i18n } from "@/helpers/i18nSet";
import moment from "moment";
import { Endpoints } from "@/store/enums/ApiEnums";

import localeEN from "element-plus/dist/locale/en.js";
import localeSK from "element-plus/dist/locale/sk.js";
import localeHU from "element-plus/dist/locale/hu.js";

/*import localeEN from "element-plus/lib/locale/lang/en";
import localeSk from "element-plus/lib/locale/lang/sk";
import localeHu from "element-plus/lib/locale/lang/hu";
*/

const element: any = {
  en: localeEN,
  sk: localeSK,
  hu: localeHU,
};

@Module({ namespaced: true })
class module18n extends VuexModule {
  public locale = process.env.VUE_APP_I18N_LOCALE || "en-US";
  public reload = false;
  public messages = "";
  public elementPlusMessages = element["en"];
  public currentDateformat = moment.localeData().longDateFormat("L");

  get getLocale() {
    return this.locale;
  }

  @Mutation
  public initialize(locale: string, messages: any): void {
    this.locale = locale;
    this.messages = messages;
  }

  @Mutation
  public changeLanguage(locale: string): void {
    this.locale = locale;
  }

  @Mutation
  public changeElementPlusMessages(elementPlusMessages: any): void {
    this.elementPlusMessages = elementPlusMessages;
  }

  @Mutation
  public changeCurrentDateformat(currentDateformat: string): void {
    this.currentDateformat = currentDateformat;
  }

  @Mutation
  public setReload(reload: boolean): void {
    this.reload = reload;
  }

  @Action
  initializeI18n(lang = "") {
    if (typeof lang == "string" && lang.length == 0) {
      //NOTE: after login is set active user (gui_locale), when is loged use globaly used locale
      lang = this.context.rootGetters["users/activeUserGuiLocale"] ?? this.context.rootGetters["i18n/getLocale"].locale ?? "";
    }

    router.isReady().then(() => {
      if (typeof lang == "number") {
        // NOTE: locale id in lang parameter
        const localeEnum = this.context.rootGetters["enums/getEnums"].locale;
        const loc = Object.entries(localeEnum).find((i: any) => i[1].id === lang ?? null);
        if (loc && loc[0]) {
          lang = loc[0];
        }
      }
      const query =
        Object.keys(router.currentRoute.value.query).length === 0
          ? { locale: "en-US" }
          : router.currentRoute.value.query; //get query from url
      const locale = lang === "" ? getLocale(query) : getLocale(lang); //helper class return locale
      const shortLocale = locale.substring(0, 2); //set short locale sk, en, ...

      this.context.dispatch("setI18n", locale); //set locale
      localStorage.setItem("locale", locale);

      //moment.js
      moment.locale(shortLocale);
      this.context.commit("changeCurrentDateformat", moment.localeData().longDateFormat("L"));

      this.context.commit("changeElementPlusMessages", element[shortLocale]); //set elementPlus

      //html tag
      const getHTMLTag = document.documentElement;
      getHTMLTag.setAttribute("lang", shortLocale);
    });
  }

  @Action({ rawError: true })
  setI18n(locale: string): Promise<any> {
    return AxiosService.get(Endpoints.ENDPOINT_COMMON_TRANSLATION + locale).then(
      (response) => {
        const messages = response.data.data;

        this.context.commit("initialize", { locale, messages });

        i18n.global.locale.value = locale; //set new global lang
        i18n.global.setLocaleMessage(locale, messages); //set new global messages

        return Promise.resolve(response);
      },
      (error) => {
        const message =
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return Promise.reject(message);
      }
    );
  }

  @Action
  reloadLocale() {
    //set reload to true and after false in component
    this.context.commit("setReload", true);
  }
}

export default module18n;
