import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";

interface Messages {
  type: string;
  body: {
    message?: string;
    response?: {
      statusText?: string;
      status?: string;
      data?: {
        message?: string;
      };
    };
  };
}

@Module({ namespaced: true })
class Notify extends VuexModule {
  public notification = [] as any;
  public shake = false;

  @Mutation
  public pushNotification(notification: any): void {
    this.notification.push({ ...notification });
  }

  @Mutation
  public removeNotification(id: string): void {
    //this.notification = [];
    this.notification = this.notification.filter(function (obj: any) {
      return obj.id !== id;
    });
  }

  @Mutation
  public shakeStatus(status: boolean): void {
    this.shake = status;
  }

  @Action /*({ rawError: true })*/ addNotification(notification: Messages): void {
    const message =
      (notification.body.response && notification.body.response.data && notification.body.response.data.message) ||
      notification.body.message;

    const id = "_" + Math.random().toString(36).substr(2, 9);

    const result = {
      id: id,
      type: notification.type,
      status: notification.body.response?.status,
      reason: notification.body.response?.statusText,
      body: message,
    };

    let isExist = false;
    const statusToFind = notification.body.response?.status;

    for (const item of this.notification) {
      if (
        Object.prototype.hasOwnProperty.call(item, "status") &&
        item.status === statusToFind &&
        item.body === message
      ) {
        isExist = true;
        break;
      }
    }

    if (!isExist) {
      this.context.commit("pushNotification", result);
    }

    // Remove notification
    setTimeout(() => {
      this.context.dispatch("resetNotification", { id: id, root: true });
    }, 10000);

    //shake button if is status "error"
    if (notification.type === "error") {
      this.context.commit("shakeStatus", true);
      //navigator.vibrate([200, 30, 200]); //mobile vibration
      setTimeout(() => {
        this.context.commit("shakeStatus", false);
      }, 1000);
    }
  }

  @Action
  resetNotification(paylod: { id: string; root: string }): void {
    this.context.commit("removeNotification", paylod.id);
  }
}

export default Notify;
