import { RouteRecordRaw } from "vue-router";

export const Payment: Array<RouteRecordRaw> = [
  {
    path: "/payments",
    name: "payments",
    children: [
      {
        path: "payout-affiliate",
        name: "payout-affilate-management-index",
        component: () => import("@/views/payment/payout-management/PayoutAffilateManagementIndex.vue"),
        meta: { resources: ["PayoutAffiliate"] },
      },
      {
        path: "payout-request",
        name: "payout-request-management-index",
        component: () => import("@/views/payment/payout-management/PayoutRequestManagementIndex.vue"),
        meta: { resources: ["PayoutRequest"] },
      },
      {
        path: "payout-history",
        name: "payout-history-management-index",
        component: () => import("@/views/payment/payout-management/PayoutHistoryManagementIndex.vue"),
        meta: { resources: ["PayoutHistory"] },
      },
    ],
  },
];
export default Payment;
