import { load } from "recaptcha-v3";

const _siteKey: string = process.env.VUE_APP_RECAPTCHA_SITEKEY || "";

//load recaptcha token with sitekey
//action is name, exp.: "login", "registration" or "F-E-R-O"

export const generateRecaptchaToken = (action: string) => {
  return new Promise((resolve) => {
    if (_siteKey !== "") {
      load(_siteKey, { useRecaptchaNet: true, autoHideBadge: false }).then((recaptcha) => {
        recaptcha.execute(action).then((token) => {
          resolve(token);
        });
      });
    } else {
      resolve("fakeTokenRecaptchaInRecaptchaTS"); //unit test
    }
  });
};
