import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
//import User from "@/store/modules/auth.module";
//import { Key } from "element-plus";

export interface StoreInfo {
  classes: {
    header?: Array<string>;
    headerContainer?: Array<string>;
    headerMobile?: Array<string>;
    headerMenu?: Array<string>;
    aside?: Array<string>;
    asideMenu?: Array<string>;
    asideToggle?: Array<string>;
    toolbar?: Array<string>;
    toolbarContainer?: Array<string>;
    content?: Array<string>;
    contentContainer?: Array<string>;
    footerContainer?: Array<string>;
    sidebar?: Array<string>;
    pageTitle?: Array<string>;
  };
}

@Module
class BodyModule extends VuexModule implements StoreInfo {
  classes = {};

  /**
   * Get current page title
   * @returns string
   */

  /*get getClasses() {
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        return (position) => {
          if (typeof position !== "undefined") {
            return this.classes[position];
          }
          return this.classes;
        };
      }
    
      @Mutation
      [Mutations.SET_CLASSNAME_BY_POSITION](payload: { [key: string]: string }) {
        const { position, className } = payload;
        if (!this.classes[position]) {
          this.classes[position] = [];
        }
        this.classes[position].push(className);
      }*/

  @Action
  [Actions.ADD_BODY_CLASSNAME](className: string) {
    document.body.classList.add(className);
  }

  @Action
  [Actions.REMOVE_BODY_CLASSNAME](className: string) {
    document.body.classList.remove(className);
  }

  @Action
  [Actions.ADD_BODY_ATTRIBUTE](payload: any) {
    const { qulifiedName, value } = payload;
    document.body.setAttribute(qulifiedName, value);
  }

  @Action
  [Actions.REMOVE_BODY_ATTRIBUTE](payload: any) {
    const { qulifiedName } = payload;
    document.body.removeAttribute(qulifiedName);
  }

  @Action
  [Actions.ADD_CLASSNAME](payload: any) {
    this.context.commit(Mutations.SET_CLASSNAME_BY_POSITION, payload);
  }
}

export default BodyModule;
