import { RouteRecordRaw } from "vue-router";

export const Automation: Array<RouteRecordRaw> = [
  {
    path: "/automations",
    name: "automation",
    component: () => import("@/views/automation/Automation.vue"),
    meta: { resources: ["automation"] },
    children: [
      {
        path: "",
        name: "automation-index",
        component: () => import("@/views/automation/AutomationIndex.vue"),
        meta: { resources: ["automation"] },
      },
      {
        path: "/automations/create",
        name: "automation-create",
        component: () => import("@/views/automation/AutomationCreate.vue"),
        meta: { resources: ["automation"] },
      },
      {
        path: "/automations/edit/:id",
        name: "automation-edit",
        component: () => import("@/views/automation/AutomationEdit.vue"),
        meta: { resources: ["automation"] },
      },
    ],
  },
];
export default Automation;
