<template>
  <div class="fw-bold fs-5">{{ title }}</div>
  <div>{{ body }}</div>
</template>
<script lang="ts">
  import { Prop } from "vue-property-decorator";
  import { Vue } from "vue-class-component";

  export default class NotificationsMessage extends Vue {
    @Prop({ required: true }) private title!: string;
    @Prop({ required: true }) private body!: string;
  }
</script>
