import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";

@Module({ namespaced: true })
class moduleRouteMeta extends VuexModule {
  private routeMeta: any = {};

  @Mutation
  private SET_ROUTE_META(meta: any): void {
    this.routeMeta = meta;
  }

  @Action
  public setRouteMeta(meta: any): void {
    this.context.commit("SET_ROUTE_META", meta);
  }

  get getRouteMeta(): any {
    return this.routeMeta;
  }
}

export default moduleRouteMeta;
