import { Module, VuexModule, Action, Mutation } from "vuex-module-decorators";
import { Endpoints } from "@/store/enums/ApiEnums";
import AxiosService from "@/services/AxiosService";

@Module({ namespaced: true })
class UsersStore extends VuexModule {
  //NOTE: partners === publishers === affiliates
  public Partners = [] as any;
  public ActiveUser = {} as any;

  get fetchedPartners() {
    return this.Partners;
  }

  get activeUser() {
    return this.ActiveUser;
  }

  get activeUserAccountId() {
    return this.ActiveUser.account_id;
  }

  get activeUserGuiLocale() {
    return this.ActiveUser.gui_locale;
  }

  @Mutation
  SET_PARTNERS(payload: any): void {
    this.Partners = payload;
  }

  @Mutation
  PUSH_ACTIVEUSER_INTO_PARTNERS(payload: any): void {
    this.Partners.push(payload);
  }

  @Mutation
  SET_ACTIVE_USER(payload: any): void {
    this.ActiveUser = payload;
  }

  @Mutation
  public SET_ACTIVE_USER_GUI_LOCALE(locale) {
    this.ActiveUser.gui_locale = locale;
  }

  @Action
  public setActiveUser(payload: any) {
    this.context.commit("SET_ACTIVE_USER", payload);
  }

  @Action
  public setActiveUserGuiLocale(locale) {
    this.context.commit("SET_ACTIVE_USER_GUI_LOCALE", locale);
  }

  @Action
  public fetchPartners(query) {
    if (query.length > 2) {
      const filter =
        "&filter[and][or]" + "[name]" + "[like]=" + query + "&filter[and][or]" + "[email]" + "[like]=" + query;
      AxiosService.get(Endpoints.ENDPOINT_USER + "?filter[type][eq]=2" + filter).then((response) => {
        const data = response.data.data;
        this.context.commit("SET_PARTNERS", data);
      });
    } else {
      AxiosService.get(Endpoints.ENDPOINT_USER + "?filter[type][eq]=2").then((response) => {
        const data = response.data.data;
        this.context.commit("SET_PARTNERS", data);
      });
    }
  }

  @Action
  public fetchUserExpandAccount(id) {
    AxiosService.get(Endpoints.ENDPOINT_USER + "/" + id + "&expand[]=account").then((response) => {
      const data = response.data.data;
      this.context.commit("SET_ACTIVE_USER", data);
      this.context.commit("PUSH_ACTIVEUSER_INTO_PARTNERS", data);
    });
  }
}

export default UsersStore;
