import { RouteRecordRaw } from "vue-router";

export const CampaignPartner: Array<RouteRecordRaw> = [
  {
    path: "/partners",
    name: "partner-management-index",
    component: () => import("@/views/partner/partner-management/PartnerManagementIndex.vue"),
    meta: { resources: ["CampaignPartner"] },
  },
];
export default CampaignPartner;
