<template>
  <div class="notifications-list">
    <notifications-message v-for="(notification, index) in notifications" :key="index" :notification="notification" />
  </div>
</template>

<script lang="ts">
  import { Options, Vue } from "vue-class-component";
  import { namespace } from "s-vuex-class";
  import NotificationsMessage from "@/components/notifications/NotificationsMessage.vue";

  const Notify = namespace("notifications");

  @Options({
    components: {
      NotificationsMessage,
    },
  })
  export default class NotificationsList extends Vue {
    @Notify.State("notification")
    private notifications!: any;
  }
</script>
