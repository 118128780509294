import axios, { AxiosError, AxiosResponse } from "axios";
import authHeader from "../helpers/auth-header";
import store from "@/store";
import { router } from "../router";
import { Actions } from "@/store/enums/StoreEnums";

authHeader();

class AxiosService {
  unauthorized(error: AxiosError, notify = true) {
    if (error.response) {
      if (error.response.status === 401) {
        /* store
          .dispatch(
            Actions.NOTIFY_ADD_NOTIFICATION,
            {
              type: "error",
              body: error,
            },
            {
              root: false, //root access to store
            }
          )
          .then(() => {
            store.dispatch(Actions.LOGOUT, false);
            router.push("/login");
          });*/
        store.dispatch(Actions.LOGOUT, false);
        router.push("/login");
      } else if (error.response.status === 403) {
        //forbidden
        router.push("/dashboard");
        if (notify) {
          store.dispatch(
            Actions.NOTIFY_ADD_NOTIFICATION,
            {
              type: "error",
              body: error,
            },
            {
              root: true, //root access to store
            }
          );
        }
      } else {
        if (notify) {
          store.dispatch(
            Actions.NOTIFY_ADD_NOTIFICATION,
            {
              type: "error",
              body: error,
            },
            {
              root: true, //root access to store
            }
          );
        }
      }
    }
  }

  async get(path: string, notify = true) {
    return await axios
      .get(path)
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        this.unauthorized(error, notify);
        return Promise.reject(error);
      });
  }

  async post(path: string, data: any, notify = true, headers: any = null) {
    return await axios
      .post(path, data, headers)
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        this.unauthorized(error, notify);
        return Promise.reject(error);
      });
  }

  async put(path: string, data: any, notify = true) {
    return await axios
      .put(path, data)
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        this.unauthorized(error, notify);
        return Promise.reject(error);
      });
  }

  async delete(path: string, data?: any, notify = true) {
    return await axios
      .delete(path, data)
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        this.unauthorized(error, notify);
        return Promise.reject(error);
      });
  }
}

export default new AxiosService();
