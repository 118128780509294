import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { Actions } from "@/store/enums/StoreEnums";

interface Load {
  loading: boolean;
}

interface ProgressBar {
  //example: key = selectAll
  [key: string]: {
    id?: number;
    status: string;
    percentage: number;
    result: Record<string, unknown[]>;
  };
}

@Module
class Loading extends VuexModule {
  public loading = false;
  public progressBar: ProgressBar = {};

  @Mutation
  public changeLoading(status: boolean): void {
    this.loading = status;
  }

  @Mutation
  public changeProgress(payload: ProgressBar): void {
    this.progressBar = payload;
  }

  @Action
  setLoading(loading: Load, time = 0): void {
    const bodyClassName = loading ? Actions.ADD_BODY_CLASSNAME : Actions.REMOVE_BODY_CLASSNAME;

    setTimeout(() => {
      this.context.dispatch(bodyClassName, "page-loading").then(() => {
        this.context.commit("changeLoading", loading);
      });
    }, time);
  }

  @Action
  deleteProgress(key: string): void {
    const _progressBar = this.progressBar;
    delete _progressBar[key];
    this.context.commit("changeProgress", _progressBar);
  }
}

export default Loading;
