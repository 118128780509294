import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

interface Automation {
  type: string;
  hook: string;
  parent_id: number | null;
  campaign_id: number | null;
  name: string;
  description: string;
  valid_from: string;
  valid_to: string;
  is_enabled: number;
  conditions: Condition;
  actions: Actions;
  options: [];
}

interface Condition {
  [index: number]: {
    type: string;
    condition: string;
    valid_from: string;
    valid_to: string;
    is_enabled: number;
    items: Rule;
  };
}

export type Rule = Array<{
  type: string;
  rule: string;
  attribute: string;
  operator: string;
  payload: string[] | number | string;
}>;

export type Actions = Array<{
  action: string;
  valid_from: string | null;
  valid_to: string | null;
  is_enabled: number;
  name: string | null;
  description: string | null;
}>;

interface AttributeSelectAndOperators {
  //modelRule: string;
  value: Array<string>;
}

@Module({ namespaced: true })
class AutomationStore extends VuexModule {
  public automation: Automation = {} as Automation;
  // public condition: Condition = [];
  // public modelRule: AttributeSelectAndOperators["modelRule"] = "";
  public attribute: AttributeSelectAndOperators["value"] = [];
  public indexes: string[] = [];
  public ruleTabs: string[] = [];

  @Mutation
  public addIndexes(payload): void {
    if (!this.indexes.includes(payload)) {
      this.indexes.push(payload);
    }
  }

  @Mutation
  public setTabRules(payload): void {
    this.ruleTabs = payload;
  }

  @Mutation
  public addAttributeValuesWithOperators(payload): void {
    const { _modelRule, _attributeValues } = payload;
    //this.modelRule = _modelRule;
    this.attribute = _attributeValues;
  }

  @Mutation
  public setAutomation(payload): void {
    this.automation = payload;
  }

  @Mutation
  public updateAutomation(hook): void {
    this.automation = {
      type: "automation",
      hook: hook.selected,
      parent_id: null,
      campaign_id: hook.campaign_id,
      name: hook.name,
      description: hook.description,
      valid_from: hook.valid_from,
      valid_to: hook.valid_to,
      is_enabled: hook.is_enabled,
      conditions: this.automation.conditions,
      actions: this.automation.actions,
      options: this.automation.options,
    };
  }

  @Mutation
  public clearAutomation(): void {
    this.automation = {} as Automation;
    //this.modelRule = "";
    this.attribute = [];
  }

  @Mutation
  public setCondition(payload): void {
    this.automation.conditions = payload;
  }

  @Mutation
  public updateValueCondition(payload): void {
    const { conditionIndex, _rule } = payload;
    const key = Object.keys(_rule).toString();
    this.automation.conditions[conditionIndex][key] = _rule[key];
  }

  @Mutation
  public addAction(payload): void {
    this.automation.actions.push({ ...payload });
  }

  @Mutation
  public setAction(payload): void {
    this.automation.actions = payload;
  }

  @Mutation
  public addActionComponentItems(payload): void {
    const { itemIndex, _items } = payload;
    this.automation.actions[itemIndex] = Object.assign(this.automation.actions[itemIndex], _items);
  }

  @Mutation
  public removeActionComponentItems(payload): void {
    const { itemIndex, _items } = payload;
    const o1 = itemIndex ? this.automation.actions[itemIndex] : {};
    const o2 = _items;

    const diff = (obj1, obj2) => {
      for (const key in obj1) {
        if (Object.prototype.hasOwnProperty.call(obj2, key) && obj1[key] === obj2[key]) {
          delete obj1[key];
          delete obj2[key];
        }
      }
      return { ...obj1, ...obj2 };
    };

    diff(o1, o2);
  }

  @Mutation
  public deleteActionComponentItems(itemIndex): void {
    this.automation.actions.splice(itemIndex, 1);
  }

  @Mutation
  public setOptions(payload): void {
    this.automation.options = payload;
  }

  @Action({ rawError: true })
  addNewAutomation(hook): void {
    const countAutomation: number = Object.keys(this.automation).length;
    if (countAutomation === 0) {
      const payload: Automation = {
        type: "automation",
        hook: hook.selected,
        parent_id: null,
        campaign_id: hook.campaign_id,
        name: hook.name,
        description: hook.description,
        valid_from: hook.valid_from,
        valid_to: hook.valid_to,
        is_enabled: hook.is_enabled,
        conditions: [],
        actions: [],
        options: [],
      };
      this.context.commit("setAutomation", payload);
    }
  }

  @Action({ rawError: true })
  addNewCondition(_payload): void {
    const { nestedIndex, operator, type } = _payload;
    const _condition = {
      type: "condition",
      condition: operator,
      valid_from: "",
      valid_to: "",
      is_enabled: 1,
      items: [],
    };

    //const _indexesArray = (type === 'child') ? nestedIndex + '-0' : nestedIndex;

    const conditionIndexArray = nestedIndex.split("-").map(Number);
    //  const payload = {conditionIndexArray, _condition, type};
    const length = conditionIndexArray.length;

    if (length === 1 && type === "condition") {
      this.automation.conditions[conditionIndexArray[0]] = _condition;
    } else {
      const obj = this.automation.conditions;

      const newObject = this.context.dispatch("nestedPush", {
        object: obj,
        indexesArray: conditionIndexArray,
        pushObj: _condition,
        type: type,
      });
    }

    //this.context.commit("setCondition", payload);
  }

  @Action({ rawError: true })
  updateCondition(payload): void {
    const { nestedIndex, key, value } = payload;
    const _rule = {
      key: key,
      value: value,
    };

    const conditionIndexArray = nestedIndex.split("-").map(Number);
    const obj = this.automation.conditions;

    this.context.dispatch("nestedPush", {
      object: obj,
      indexesArray: conditionIndexArray,
      pushObj: _rule,
      type: "update-condition",
    });
  }

  @Action({ rawError: true })
  async getCondition(payload): Promise<void> {
    const { nestedIndex, key, value } = payload;
    const _rule = {
      key: key,
      value: value,
    };

    const conditionIndexArray = nestedIndex.split("-").map(Number);
    const obj = this.automation.conditions;

    return await this.context.dispatch("nestedPush", {
      object: obj,
      indexesArray: conditionIndexArray,
      pushObj: "",
      type: "get-condition",
    });
  }

  @Action({ rawError: true })
  addNewAction(): void {
    const _action = {
      action: "",
      //provisionNetwork: 0,
      //provisionAffiliate: 0,
      //type: 0,
      //source: 0,
      valid_from: null,
      valid_to: null,
      is_enabled: 1,
      name: null,
      description: null,
    };
    this.context.commit("addAction", _action);
  }

  @Action({ rawError: true })
  addNewRule(payload): void {
    const { nestedIndex, ruleModel } = payload;

    const _rule = {
      type: "rule",
      rule: ruleModel,
      attribute: null, //null
      operator: null, //null
      valid_from: null,
      valid_to: null,
      is_enabled: 1,
      payload: [], //[]
    };
    //this.context.commit("setRule", { nestedIndex, _rule });
    const obj = this.automation.conditions;
    this.context.dispatch("nestedPush", {
      object: obj,
      indexesArray: nestedIndex.split("-").map(Number),
      pushObj: _rule,
      type: "rule",
    });
  }

  @Action({ rawError: true })
  updateRule(payload): void {
    const { nestedIndex, itemIndex, key, value } = payload;
    const _rule = {
      key: key,
      value: value,
      itemIndex: itemIndex,
    };
    const obj = this.automation.conditions;
    this.context.dispatch("nestedPush", {
      object: obj,
      indexesArray: nestedIndex.split("-").map(Number),
      pushObj: _rule,
      type: "update-rule",
    });
  }

  @Action({ rawError: true })
  deleteRule(payload): void {
    const { nestedIndex, itemIndex } = payload;
    const _rule = {
      itemIndex: itemIndex,
    };
    const obj = this.automation.conditions;
    this.context.dispatch("nestedPush", {
      object: obj,
      indexesArray: nestedIndex.split("-").map(Number),
      pushObj: _rule,
      type: "delete-rule",
    });
  }

  @Action({ rawError: true })
  nestedPush(payload): void {
    const { object, indexesArray, pushObj, type } = payload;

    let currentArray = object;

    for (let i = 0; i < indexesArray.length; i++) {
      const index = indexesArray[i];

      if (type === "condition" && i === indexesArray.length - 2) {
        currentArray[index].items.push(pushObj);
      } else if (type === "update-condition" && i === indexesArray.length - 1) {
        currentArray[index][pushObj.key] = pushObj.value;
      } else if (type === "get-condition" && i === indexesArray.length - 1) {
        return currentArray[index];
      } else if (type === "child" && i === indexesArray.length - 1) {
        if (currentArray[index] !== undefined) {
          currentArray[index].condition = pushObj.condition;
        }
        pushObj.condition = "and";
        currentArray[index].items.push(pushObj);
      } else if (type === "rule" && i === indexesArray.length - 1) {
        currentArray[index].items.push(pushObj);
      } else if (type === "update-rule" && i === indexesArray.length - 1) {
        currentArray[index].items[pushObj["itemIndex"]][pushObj["key"]] = pushObj["value"];
      } else if (type === "delete-rule" && i === indexesArray.length - 1) {
        currentArray[index].items.splice(pushObj["itemIndex"], 1);
      } else {
        if (currentArray[index] !== undefined) {
          currentArray = currentArray[index].items;
        }
      }
    }

    return currentArray;
  }

  @Action({ rawError: true })
  public deleteCondition(payload): void {
    const { nestedIndex } = payload;

    const nestedIndexToDelete = nestedIndex.split("-").map(Number);
    const data = this.automation.conditions;

    const result = this.context.dispatch("deleteObjectAtNestedIndex", {
      data: data,
      nestedIndex: nestedIndexToDelete,
    });
  }

  @Action({ rawError: true })
  deleteObjectAtNestedIndex(payload): void {
    const { data, nestedIndex } = payload;

    let currentArray = data;

    // delete condition, if is the first line
    if (nestedIndex.length === 1) {
      currentArray.splice(nestedIndex[0], 1);
      return currentArray;
    }

    for (let i = 0; i < nestedIndex.length; i++) {
      const index = nestedIndex[i];

      if (i === nestedIndex.length - 2) {
        const getLastItem = nestedIndex[nestedIndex.length - 1];
        currentArray[index].items.splice(getLastItem, 1);
      } else {
        if (currentArray[index] !== undefined) {
          currentArray = currentArray[index].items;
        }
      }
    }

    return currentArray;
  }
}

export default AutomationStore;
