import { RouteRecordRaw } from "vue-router";

const Auth: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/auth/Login.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/auth/registration/Registration.vue"),
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("@/views/auth/ForgotPassword.vue"),
  },
  {
    path: "/reset-password/:token/:email",
    name: "reset-password",
    component: () => import("@/views/auth/ResetPassword.vue"),
  },
  {
    path: "/migration",
    name: "migration-index",
    component: () => import("@/views/auth/registration/migration/AccountUpdate.vue"),
  },
  {
    path: "/migration/:token",
    name: "migration",
    component: () => import("@/views/auth/registration/Registration.vue"),
  },
  {
    path: "/create-password/:token",
    name: "create-password",
    component: () => import("@/views/auth/ResetPassword.vue"),
  },
];

export default Auth;
