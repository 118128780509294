import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import store from "@/store";

interface ColumnType {
  name: string;
  field: string;
  hidden: boolean;
}

interface FilterType {
  field: string;
  value: any[];
}

interface ReportsState {
  initialColumns: ColumnType[];
  columns: ColumnType[];
  selectedFilter: FilterType | null;
}

@Module({ namespaced: true, store })
class ReportsStore extends VuexModule implements ReportsState {
  public initialColumns: ColumnType[] = [];
  public columns: ColumnType[] = [];
  public selectedFilter: FilterType | null = null;

  @Mutation
  setInitialColumns(payload: any) {
    this.initialColumns = payload;
  }

  @Mutation
  setColumns(payload: any) {
    this.columns = payload;
  }

  @Mutation
  resetColumns() {
    this.columns = [...this.initialColumns];
  }

  @Mutation
  updateSelectedFilterData(newFilter: FilterType) {
    this.selectedFilter = newFilter;
  }

  @Mutation
  resetSelectedFilter() {
    this.selectedFilter = { field: "", value: [] };
  }

  get getInitialColumns() {
    return this.initialColumns;
  }

  @Action
  async updateColumns(newColumns: any) {
    // const columnMap = currentColumns.map((col) => [col.field, col]);
    //
    // newColumns.forEach((col) => {
    //   console.log(col);
    //   if (columnMap.has(col.field)) {
    //     const existingCol: any = columnMap.get(col.field);
    //     for (const key in col) {
    //       existingCol[key] = col[key];
    //     }
    //   } else {
    //     columnMap.set(col.field, col);
    //   }
    // });
    //
    // this.context.commit("setInitialColumns", Array.from(columnMap.values()));
    // this.context.commit("setColumns", newColumns);
  }

  @Action
  resetToInitialColumns() {
    this.context.commit("resetColumns");
  }

  @Action
  updateFilterData(newFilter: string[]) {
    this.context.commit("updateSelectedFilterData", newFilter);
  }

  @Action
  resetFilterData() {
    this.context.commit("resetSelectedFilter");
  }

  // Removed the incorrect initColumns mutation as it was redundant
}

export default ReportsStore;
