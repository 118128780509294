import { useToast, ToastInterface, POSITION } from "vue-toastification";
import TitleToast from "@/components/notifications/TitleToast.vue";

type ToastTypes = "info" | "success" | "warning" | "error";

type MyToastInterface = {
  [type in ToastTypes]: (title: string, body: string) => void;
};

export const useMyToast = () => {
  // Get the original toast interface
  const toast = useToast();

  // Helper method that sets up our toasts with title
  const createMyToastMethod = <M extends ToastTypes>(method: M): MyToastInterface[M] => {
    return (title, body) =>
      toast[method](
        { component: TitleToast, props: { title, body } },
        {
          // Setting the global default position
          position: POSITION.TOP_RIGHT,
        }
      );
  };

  // Create and return our new interface
  const myToastInterface: MyToastInterface = {
    info: createMyToastMethod("info"),
    success: createMyToastMethod("success"),
    warning: createMyToastMethod("warning"),
    error: createMyToastMethod("error"),
  };
  return myToastInterface;
};
